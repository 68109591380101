<template>
    <div class="standalone user-password-reset">
        <div>
            <form @submit.prevent="resetPassword">
                <img src="@/assets/images/brand/star-logo-full.png" class="full-logo" />
                <div class="danger" v-if="errorMessage">
                    {{errorMessage}}
                </div>
                <div class="form-group">
                    Email address
                    <input type="text" v-model="email" />
                </div>
                <div class="form-group">
                    Password
                    <input type="password" v-model="password" />
                </div>
                <div class="controls">
                    <router-link :to="{ name: 'UserLogin' }" class="button button-2">Cancel</router-link>
                    <button type="submit">Reset Password</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {

    data: function(){
        return {
            email: '',
            password: '',
            errorMessage: ''
        }
    },
    methods: {
        resetPassword: function(){
            const $this = this; 
            if (this.email.length && this.password.length){
                axios({
                    method: 'post', 
                    url: 'https://localhost:49155/Users/reset-password',    
                    headers: { 
                        'Content-Type': 'text/json',
                    },
                    data: {
                        Email: $this.email,
                        Password: $this.password
                    }
                }).then(function(response){
                    if (response.status === 200){
                        $this.$router.push({name: "UserLogin"});
                    } else {
                        $this.errorMessage = "Bad response from server";
                    }
                }, function(error){
                    $this.errorMessage = error.response.data.message;
                });
            } else {
                this.errorMessage = "Enter a valid email address";
            }
        }
    },
    computed: {
    },
    created: function(){
    }
}

</script>
<style lang="scss">
@import "../../assets/scss/variables.scss";
.user-password-reset{
    
    .full-logo{
        width: 200px;
        margin: 0px auto 0;
        display: block;
    }
}
</style>